import { Button } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../../lib/components/Buttons/ButtonsContainer';
import { message } from 'antd';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { EditionEntity } from '../../../../../lib/model/Book';
import { useEditionsApi } from '../hooks/useEditionsApi';
import { PublishButton } from '../../../../../lib/components/Buttons/PublishButton';
import { ImportButton } from '../../../../../lib/components/Buttons/ImportButton';

interface IProps {
	editions: EditionEntity[];
	on_remove_success: () => void;
}

export const EditionsDataTable: FC<IProps> = ({ editions, on_remove_success }) => {
	const navigate = useNavigate();
	const { remove, publish: publish_edition, import_book } = useEditionsApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	async function publish(edition_num_id: string) {
		message.loading('Publicando...', 0);
		await publish_edition.do_index(edition_num_id);
		message.success('Edição publicada com sucesso.');
		window.location.reload();
	}

	async function importbook(edition_num_id: string) {
		message.loading('Importando...', 0);
		await import_book.get(edition_num_id);
		message.success('Edição importada com sucesso.');
		window.location.reload();
	}

	return (
		<DataTable
			{...{
				row_height: () => 150,
				rowData: editions,
				buttons: [<AddButton onClick={() => navigate('novo')} />],
				columns: [
					{
						col_def: {
							field: 'cover',
							headerName: 'Capa',
							resizable: false,
							width: 147,
						},
						renderer: (props: { data: { book_num_id: string; name: string; num_id: string } }) => (
							<img
								{...{
									style: {
										width: 230,
										height: 150,
										padding: 8,
									},
									src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/books/cover/${props.data.book_num_id}/editions/${props.data.num_id}.jpg`,
									alt: `${props.data.name}`,
								}}
								onError={(e: any) => {
									e.target.onerror = null;
									e.target.src = `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/books/cover/default/no-image.png`;
								}}
							/>
						),
					},
					ColumnFactory.Link(
						{
							field: 'title',
							headerName: 'Titulo',
							resizable: true,
							width: 200,
						},
						(props: { data: { id: string } }) => props.data.id
					),
					{
						field: 'number',
						headerName: 'Número',
						resizable: true,
						width: 180,
					},
					{
						field: 'authors',
						headerName: 'Autor(es)',
						resizable: true,
						width: 240,
					},
					{
						field: 'coordinators',
						headerName: 'Coordenador(es)',
						resizable: true,
						width: 180,
					},
					{
						col_def: {
							field: 'is_published',
							headerName: 'Publicado?',
							resizable: true,
							width: 180,
						},
						renderer: (props: { data: { is_published?: boolean } }) => (
							<>
								{props.data.is_published ? (
									<CheckCircleOutlined style={{ color: 'green' }} />
								) : (
									<CloseCircleOutlined style={{ color: 'red' }} />
								)}
							</>
						),
					},
					{
						col_def: {
							field: 'url',
							headerName: 'Link na Plataforma',
							resizable: true,
							width: 180,
						},
						renderer: (props: {
							data: { book_num_id: string; num_id: string; is_published?: boolean };
						}) => (
							<>
								{props.data.is_published ? (
									<a
										href={`${process.env.REACT_APP_PLATFORM_DOMAIN}/v2/livro/${props.data.book_num_id}/${props.data.num_id}`}
										target='_blank'
									>
										<Button
											{...{
												type: 'primary',
												title: 'Vizualizar na Plataforma',
												style: { color: '#fff' },
											}}
										>
											<div className='flex items-center'>
												<EyeOutlined />
											</div>
										</Button>
									</a>
								) : (
									<></>
								)}
							</>
						),
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 350,
						},
						renderer: (props: { data: { id: string; title: string; num_id: string } }) => (
							<ButtonsContainer>
								<Button {...{ type: 'ghost', onClick: () => navigate(`${props.data.id}/capitulos`) }}>
									Capítulos
								</Button>
								<PublishButton
									{...{
										onClick: () => {
											publish(props.data.num_id);
										},
										text_confirm:
											'Deseja publicar esta edição na plataforma? Obs: Todos os capítulos devem estar devidamente cadastrados.',
										loading: !!publish_edition?.loading,
										style: { marginLeft: 15 },
									}}
								/>
								<ImportButton
									{...{
										onClick: () => {
											importbook(props.data.num_id);
										},
										text_confirm:
											'Deseja efetuar a importação deste livro para a loja digital? Obs: Todos os capítulos devem estar devidamente cadastrados.',
										loading: !!import_book?.loading,
										style: { marginLeft: 15 },
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
