import { Button, Popconfirm } from 'antd';
import React, { CSSProperties, FC } from 'react';
import { UploadIcon } from '../icons/UploadIcon';

interface IProps {
	onClick: () => void;
	text_confirm: string;
	loading: boolean;
	style?: CSSProperties;
	text?: string;
}

export const ImportButton: FC<IProps> = ({ onClick, text_confirm, loading, style, text = 'Importar' }) => (
	<Popconfirm
		{...{ title: text_confirm, placement: 'top', onConfirm: onClick, okText: 'Sim', cancelText: 'Não' }}
	>
		<Button {...{ type: 'primary', loading, style }}>
			{!loading && (
				<div className='flex items-center'>
					<UploadIcon />
					{text}
				</div>
			)}
		</Button>
	</Popconfirm>
);
